<template>
  <div class="container">
    <nav-bar :title="title"></nav-bar>
    <template v-if="this.index === 1">
      <div class="margin-lr">
        <img :src="image" width="100%" />
        <p class="w100 margin-bottom padding-left">干粉设计用量（kg） m={{ f1M || '' }}</p>
        <p class="w100 margin-bottom padding-left">开口补偿系数 K={{ f1K || '' }}</p>
        <p class="w100 margin-bottom padding-left">防护区内总面积（㎡） Av={{ f1A || '' }}</p>
        <p class="w100 margin-bottom padding-left">开口总面积（㎡） A0={{ f1.a || '' }}</p>
        <p class="w100 margin-bottom padding-left">防护区净容积（m³） V={{ f1V || '' }}</p>
      </div>
      <md-field>
        <v-input title="防护区长（m）" type="money" v-model="f1.l" :solid="false" align="right" rules="required|only-num"></v-input>
        <v-input title="防护区宽（m）" type="money" v-model="f1.w" :solid="false" align="right" rules="required|only-num"></v-input>
        <v-input title="防护区高（m）" type="money" v-model="f1.h" :solid="false" align="right" rules="required|only-num"></v-input>
        <v-input title="不燃烧体总体积（m³）" type="money" v-model="f1.v" :solid="false" align="right" rules="required|only-num"></v-input>
        <v-input title="开口总面积（㎡）" type="money" v-model="f1.a" :solid="false" align="right" rules="required|only-num"></v-input>
      </md-field>
    </template>
    <template v-if="this.index === 2">
      <div class="margin-lr">
        <img :src="image" width="100%" />
        <p class="w100 margin-bottom padding-left">二氧化碳设计用量（kg） M={{ f2M || '' }}</p>
        <p class="w100 margin-bottom padding-left">可燃物物质系数 Kb={{ f2.kb || '' }}</p>
        <p class="w100 margin-bottom padding-left">防护区内总面积（㎡） Av={{ f2A || '' }}</p>
        <p class="w100 margin-bottom padding-left">开口总面积（㎡） A0={{ f2.a || '' }}</p>
        <p class="w100 margin-bottom padding-left">防护区净容积（m³） V={{ f2V || '' }}</p>
        <img :src="image_2_1" width="100%" />
        <p class="w100 margin-bottom padding-left">泄压口面积（㎡） Ax={{ f2Ax || '' }}</p>
        <p class="w100 margin-bottom padding-left">设计喷放时间（min） t=1</p>
        <p class="w100 margin-bottom padding-left">二氧化碳喷射率（kg/min） Q={{ f2Q || '' }}</p>
        <p class="w100 margin-bottom padding-left">维护结构允许压强（Pa） P={{ f2.p || '' }}</p>
      </div>
      <md-field>
        <v-field
          title="可燃物物质系数"
          :content="f2.kbText"
          arrow
          @click="fireTypeShow = true"
          rules="required"
          :value="f2.kb"
          align="right"
        ></v-field>
        <v-input title="防护区长（m）" type="money" v-model="f2.l" :solid="false" align="right" rules="required|only-num"></v-input>
        <v-input title="防护区宽（m）" type="money" v-model="f2.w" :solid="false" align="right" rules="required|only-num"></v-input>
        <v-input title="防护区高（m）" type="money" v-model="f2.h" :solid="false" align="right" rules="required|only-num"></v-input>
        <v-input title="不燃烧体总体积（m³）" type="money" v-model="f2.v" :solid="false" align="right" rules="required|only-num"></v-input>
        <v-input title="开口总面积（㎡）" type="money" v-model="f2.a" :solid="false" align="right" rules="required|only-num"></v-input>
        <v-input
          title="防护区最低环境温度（℃）"
          type="digit"
          v-model="f2.t"
          :solid="false"
          align="right"
          rules="required|only-num"
        ></v-input>
        <v-field
          title="建筑物最低允许压强（Pa）"
          :content="f2.pText"
          arrow
          @click="buildTypeShow = true"
          rules="required"
          :value="f2.p"
          align="right"
        ></v-field>
      </md-field>
    </template>
    <template v-if="this.index === 3">
      <div class="margin-lr">
        <img :src="image" width="100%" />
        <p class="w100 margin-bottom padding-left">灭火设计用量（kg） W={{ f3W || '' }}</p>
        <p class="w100 margin-bottom padding-left">海拔高度修正系数 K={{ f3K || '' }}</p>
        <p class="w100 margin-bottom padding-left">灭火设计浓度（%） C1={{ f3.c1 || '' }}</p>
        <p class="w100 margin-bottom padding-left">最低环境温度下灭火剂质量体积（m³/kg） S={{ f3S || '' }}</p>
        <p class="w100 margin-bottom padding-left">防护区净容积（m³） V={{ f3V || '' }}</p>
        <img :src="image_3_1" width="100%" />
        <p class="w100 margin-bottom padding-left">泄压口面积（㎡） F={{ f3F || '' }}</p>
        <p class="w100 margin-bottom padding-left">设计喷放时间（s） t={{ f3t || '' }}</p>
        <p class="w100 margin-bottom padding-left">平均喷放速率（kg/s） Q={{ f3Q || '' }}</p>
        <p class="w100 margin-bottom padding-left">维护结构允许压强（Pa） P={{ f3.p || '' }}</p>
      </div>
      <md-field>
        <v-field
          title="灭火设计浓度（%）"
          :content="f3.c1Text"
          arrow
          @click="fireTypeShow = true"
          rules="required"
          :value="f3.c1"
          align="right"
        ></v-field>
        <v-input title="防护区长（m）" type="money" v-model="f3.l" :solid="false" align="right" rules="required|only-num"></v-input>
        <v-input title="防护区宽（m）" type="money" v-model="f3.w" :solid="false" align="right" rules="required|only-num"></v-input>
        <v-input title="防护区高（m）" type="money" v-model="f3.h" :solid="false" align="right" rules="required|only-num"></v-input>
        <v-input title="不燃烧体总体积（m³）" type="money" v-model="f3.v" :solid="false" align="right" rules="required|only-num"></v-input>
        <v-input
          title="防护区最低环境温度（℃）"
          type="digit"
          v-model="f3.t"
          :solid="false"
          align="right"
          rules="required|only-num"
        ></v-input>
        <v-input title="防护区海拔高度（m）" type="digit" v-model="f3.H" :solid="false" align="right" rules="required|only-num"></v-input>
        <v-field
          title="建筑物最低允许压强（Pa）"
          :content="f3.pText"
          arrow
          @click="buildTypeShow = true"
          rules="required"
          :value="f3.p"
          align="right"
        ></v-field>
      </md-field>
    </template>
    <template v-if="this.index === 4">
      <div class="margin-lr">
        <img :src="image" width="100%" />
        <p class="w100 margin-bottom padding-left">灭火设计用量（kg） W={{ f4W || '' }}</p>
        <p class="w100 margin-bottom padding-left">海拔高度修正系数 K={{ f4K || '' }}</p>
        <p class="w100 margin-bottom padding-left">灭火设计浓度（%） C1={{ f4.c1 || '' }}</p>
        <p class="w100 margin-bottom padding-left">最低环境温度下灭火剂质量体积（m³/kg） S={{ f4S || '' }}</p>
        <p class="w100 margin-bottom padding-left">防护区净容积（m³） V={{ f4V || '' }}</p>
        <img :src="image_4_1" width="100%" />
        <p class="w100 margin-bottom padding-left">泄压口面积（㎡） F={{ f4F || '' }}</p>
        <p class="w100 margin-bottom padding-left">设计喷放时间（s） t={{ f4t || '' }}</p>
        <p class="w100 margin-bottom padding-left">平均喷放速率（kg/s） Q={{ f4Q || '' }}</p>
        <p class="w100 margin-bottom padding-left">维护结构允许压强（Pa） P={{ f4.p || '' }}</p>
      </div>
      <md-field>
        <v-field
          title="灭火设计浓度（%）"
          :content="f4.c1Text"
          arrow
          @click="fireTypeShow = true"
          rules="required"
          :value="f4.c1"
          align="right"
        ></v-field>
        <v-input title="防护区长（m）" type="money" v-model="f4.l" :solid="false" align="right" rules="required|only-num"></v-input>
        <v-input title="防护区宽（m）" type="money" v-model="f4.w" :solid="false" align="right" rules="required|only-num"></v-input>
        <v-input title="防护区高（m）" type="money" v-model="f4.h" :solid="false" align="right" rules="required|only-num"></v-input>
        <v-input title="不燃烧体总体积（m³）" type="money" v-model="f4.v" :solid="false" align="right" rules="required|only-num"></v-input>
        <v-input
          title="防护区最低环境温度（℃）"
          type="digit"
          v-model="f4.t"
          :solid="false"
          align="right"
          rules="required|only-num"
        ></v-input>
        <v-input title="防护区海拔高度（m）" type="digit" v-model="f4.H" :solid="false" align="right" rules="required|only-num"></v-input>
        <v-field
          title="建筑物最低允许压强（Pa）"
          :content="f4.pText"
          arrow
          @click="buildTypeShow = true"
          rules="required"
          :value="f4.p"
          align="right"
        ></v-field>
      </md-field>
    </template>
    <template v-if="this.index === 5">
      <div class="margin-lr">
        <img :src="image" width="100%" />
        <p class="w100 margin-bottom padding-left">灭火设计用量（kg） W={{ f5W || '' }}</p>
        <p class="w100 margin-bottom padding-left">容积修正系数 K={{ f5K || '' }}</p>
        <p class="w100 margin-bottom padding-left">灭火设计密度（kg/m³） C2={{ f5.c2 || '' }}</p>
        <p class="w100 margin-bottom padding-left">防护区净容积（m³） V={{ f5V || '' }}</p>
      </div>
      <md-field>
        <v-field
          title="灭火设计密度（kg/m³）"
          :content="f5.c2Text"
          arrow
          @click="fireTypeShow = true"
          rules="required"
          :value="f5.c2"
          align="right"
        ></v-field>
        <v-input title="防护区长（m）" type="money" v-model="f5.l" :solid="false" align="right" rules="required|only-num"></v-input>
        <v-input title="防护区宽（m）" type="money" v-model="f5.w" :solid="false" align="right" rules="required|only-num"></v-input>
        <v-input title="防护区高（m）" type="money" v-model="f5.h" :solid="false" align="right" rules="required|only-num"></v-input>
        <v-input title="不燃烧体总体积（m³）" type="money" v-model="f5.v" :solid="false" align="right" rules="required|only-num"></v-input>
      </md-field>
    </template>
    <template v-if="this.index === 6">
      <div class="margin-lr">
        <img :src="image" width="100%" />
        <p class="w100 margin-bottom padding-left">单元的最小需配灭火级别 Q={{ f6Q || '' }}</p>
        <p class="w100 margin-bottom padding-left">修正系数 K={{ f6.k || '' }}</p>
        <p class="w100 margin-bottom padding-left">单元保护面积（㎡） S={{ f6S || '' }}</p>
        <p class="w100 margin-bottom padding-left">单位灭火级别最大保护面积（㎡/A或㎡/B） U={{ f6U || '' }}</p>
        <p class="w100 margin-bottom padding-left">
          建筑危险等级 <span class="font-bold">{{ f6VText || '' }}</span>
        </p>
        <img :src="image_6_1" width="100%" />
        <p class="w100 margin-bottom padding-left">灭火器最大保护距离 L={{ f6L || '' }}</p>
        <p class="w100 margin-bottom padding-left">灭火器设置点行数 R={{ f6R || '' }}</p>
        <p class="w100 margin-bottom padding-left">灭火器设置点列数 C={{ f6C || '' }}</p>
        <p class="w100 margin-bottom padding-left">计算单元中灭火器设置点个数（行*列） N={{ f6N || '' }}</p>
        <p class="w100 margin-bottom padding-left">每个设置点的最小需配灭火级别（A或 B） Qe={{ f6Qe || '' }}</p>
      </div>
      <md-field>
        <v-input title="保护面积长（m）" type="money" v-model="f6.l" :solid="false" align="right" rules="required|only-num"></v-input>
        <v-input title="保护面积宽（m）" type="money" v-model="f6.w" :solid="false" align="right" rules="required|only-num"></v-input>
        <v-field title="灭火器形式" solid>
          <md-radio name="1" v-model="f6.t" label="手提式" inline />
          <md-radio name="2" v-model="f6.t" label="推车式" inline />
        </v-field>
        <v-field title="火灾类型" solid>
          <md-radio name="1" v-model="f6.t1" label="A类火灾" inline />
          <md-radio name="2" v-model="f6.t1" label="BC类火灾" inline />
        </v-field>
        <md-check v-model="f6.b" label="属于娱乐/网吧/商场/寺庙/地下场所之一" />
        <v-field
          title="修正系数"
          :content="f6.kText"
          arrow
          @click="fireTypeShow = true"
          rules="required"
          :value="f6.k"
          align="right"
        ></v-field>
        <v-field
          title="建筑属性"
          :content="f6.typeText"
          arrow
          @click="buildUseShow = true"
          rules="required"
          :value="f6.type"
          align="right"
        ></v-field>
        <md-radio-list v-model="f6.v" :options="places" icon-size="lg">
          <template slot-scope="{ option }">
            <div v-text="option.text"></div>
            <div class="custom-brief" v-html="option.brief"></div>
          </template>
        </md-radio-list>
      </md-field>
    </template>
    <md-selector
      v-model="fireTypeShow"
      :data="fireType"
      max-height="320px"
      :title="fireTypeTitle"
      large-radius
      @choose="onFireTypeChoose"
    ></md-selector>
    <md-selector
      v-model="buildTypeShow"
      :data="buildType"
      max-height="320px"
      title="建筑物最低允许压强（Pa）"
      large-radius
      @choose="onBuildTypeChoose"
    ></md-selector>
    <md-selector
      v-model="buildUseShow"
      :data="[
        { value: 1, text: '民用建筑' },
        { value: 2, text: '工业建筑' },
      ]"
      max-height="320px"
      title="建筑属性"
      large-radius
      @choose="onBuildUseChoose"
    ></md-selector>
  </div>
</template>

<script>
import f1 from '@/assets/formula/f1.png'
import f2 from '@/assets/formula/f2.png'
import f2_1 from '@/assets/formula/f2_1.png'
import f3 from '@/assets/formula/f3.png'
import f3_1 from '@/assets/formula/f3_1.png'
import f4 from '@/assets/formula/f4.png'
import f4_1 from '@/assets/formula/f4_1.png'
import f5 from '@/assets/formula/f5.png'
import f6 from '@/assets/formula/f6.png'
import f6_1 from '@/assets/formula/f6_1.png'

import _ from 'lodash'

export default {
  data() {
    return {
      title: '',
      index: '',
      image: '',
      image_2_1: f2_1,
      image_3_1: f3_1,
      image_4_1: f4_1,
      image_6_1: f6_1,
      type: [
        '干粉全淹没灭火系统',
        '二氧化碳全淹没灭火系统',
        '七氟丙烷全淹没灭火系统',
        'IG541 混合气体全淹没灭火系统',
        '热气溶胶预制全淹没灭火系统',
        '建筑灭火器配置计算',
      ],
      formula: [f1, f2, f3, f4, f5, f6],
      fireTypeShow: false,
      buildTypeShow: false,
      fireType: [],
      buildType: [
        { value: 1200, text: '高层建筑' },
        { value: 2400, text: '一般建筑' },
        { value: 4800, text: '地下建筑' },
      ],
      f1: {
        kb: undefined,
        kbText: '',
        l: undefined,
        w: undefined,
        h: undefined,
        v: undefined,
        t: undefined,
        p: undefined,
        pText: '',
      },
      f2: {
        kb: undefined,
        kbText: '',
        l: undefined,
        w: undefined,
        h: undefined,
        v: undefined,
        t: undefined,
        p: undefined,
        pText: '',
      },
      f3: {
        c1: undefined,
        c1Text: '',
        l: undefined,
        w: undefined,
        h: undefined,
        v: undefined,
        t: undefined,
        H: undefined,
        p: undefined,
        pText: '',
      },
      f4: {
        c1: undefined,
        c1Text: '',
        l: undefined,
        w: undefined,
        h: undefined,
        v: undefined,
        t: undefined,
        H: undefined,
        p: undefined,
        pText: '',
        a: undefined,
      },
      f5: {
        c2: undefined,
        c2Text: '',
        l: undefined,
        w: undefined,
        h: undefined,
        v: undefined,
      },
      f6: {
        k: undefined,
        kText: '',
        l: undefined,
        w: undefined,
        t: undefined,
        t1: undefined,
        b: undefined,
        v: undefined,
        type: undefined,
        typeText: '',
      },
      buildUseShow: false,
      places: [],
    }
  },
  computed: {
    fireTypeTitle() {
      if (this.index === 5) {
        return '灭火设计密度（kg/m³）'
      }
      if (this.index === 6) {
        return '修正系数'
      }
      return '灭火设计浓度（%）'
    },
    f1V() {
      return this.f1.l * this.f1.w * this.f1.h - this.f1.v
    },
    f1A() {
      return (this.f1.l * this.f1.w + this.f1.l * this.f1.h + this.f1.w * this.f1.h) * 2
    },
    f1K() {
      const f1A1 = this.f1A * 0.01
      const f1A5 = this.f1A * 0.05
      const f1A15 = this.f1A * 0.15
      if (this.f1.a < f1A1) {
        return 0
      } else if (this.f1.a >= f1A1 && this.f1.a < f1A5) {
        return 2.5
      } else if (this.f1.a >= f1A5 && this.f1.a <= f1A15) {
        return 5
      } else if (this.f1.a > f1A15) {
        return '数值错误'
      }
      return undefined
    },
    f1M() {
      let m = _.round(0.65 * this.f1V + this.f1K * this.f1.a, 2)
      return m
    },
    f2V() {
      return this.f2.l * this.f2.w * this.f2.h - this.f2.v
    },
    f2A() {
      return (this.f2.l * this.f2.w + this.f2.l * this.f2.h + this.f2.w * this.f2.h) * 2
    },
    f2M() {
      let m = _.round(this.f2.kb * (0.2 * (this.f2A + 30 * this.f2.a) + 0.7 * this.f2V), 2)
      return m
    },
    f2Q() {
      return this.f2M
    },
    f2Ax() {
      return _.round(0.0076 * (this.f2Q / Math.sqrt(this.f2.p)), 2)
    },
    f3V() {
      return this.f3.l * this.f3.w * this.f3.h - this.f3.v
    },
    f3S() {
      return 0.1269 + 0.000513 * this.f3.t
    },
    f3K() {
      return 1 - this.f3.H * 0.0001
    },
    f3W() {
      return _.round(this.f3K * (this.f3V / this.f3S) * (this.f3.c1 / (100 - this.f3.c1)), 2)
    },
    f3F() {
      return _.round((0.15 * this.f3Q) / Math.sqrt(this.f3.p), 2)
    },
    f3t() {
      return this.f3.c1 === 8 ? 8 : 10
    },
    f3Q() {
      return this.f3W / this.f3t
    },
    f4V() {
      return this.f4.l * this.f4.w * this.f4.h - this.f4.v
    },
    f4S() {
      return 0.6575 + 0.0024 * this.f4.t
    },
    f4K() {
      return 1 - this.f4.H * 0.0001
    },
    f4W() {
      return _.round(this.f4K * (this.f4V / this.f4S) * Math.log(100 / (100 - this.f4.c1)), 2)
    },
    f4F() {
      return _.round((1.1 * this.f4Q) / Math.sqrt(this.f4.p), 2)
    },
    f4t() {
      return 48
    },
    f4Q() {
      return this.f4W / this.f4t
    },
    f5V() {
      return this.f5.l * this.f5.w * this.f5.h - this.f5.v
    },
    f5K() {
      let val = undefined
      if (this.f5V < 500) {
        val = 1
      } else if (this.f5V >= 500 && this.f5V < 1000) {
        val = 1.1
      } else if (this.f5V >= 1000) {
        val = 1.2
      }
      return val
    },
    f5W() {
      return _.round(this.f5.c2 * this.f5K * this.f5V, 2)
    },
    f6S() {
      return this.f6.l * this.f6.w
    },
    f6U() {
      if (this.f6.t1 === '1') {
        const type = [50, 75, 100]
        return type[this.f6.v - 1]
      } else {
        const type = [0.5, 1, 1.5]
        return type[this.f6.v - 1]
      }
    },
    f6Q() {
      return this.f6.b ? 1.3 * this.f6.k * (this.f6S / this.f6U) : this.f6.k * (this.f6S / this.f6U)
    },
    f6L() {
      if (this.f6.t1 === '1') {
        if (this.f6.t === '1') {
          const type = [15, 20, 25]
          return type[this.f6.v - 1]
        } else {
          const type = [30, 40, 50]
          return type[this.f6.v - 1]
        }
      } else {
        if (this.f6.t === '1') {
          const type = [9, 12, 15]
          return type[this.f6.v - 1]
        } else {
          const type = [18, 24, 30]
          return type[this.f6.v - 1]
        }
      }
    },
    f6R() {
      return _.ceil(this.f6.l / (1.414 * this.f6L))
    },
    f6C() {
      return _.ceil(this.f6.w / (1.414 * this.f6L))
    },
    f6N() {
      return this.f6R * this.f6C
    },
    f6Qe() {
      let Qe = this.f6Q / this.f6N
      const type = this.f6.t1 === '1' ? [3, 2, 1] : [89, 55, 21]
      const QeMin = type[this.f6.v - 1]
      if (Qe < QeMin) {
        Qe = QeMin
      }
      return _.round(Qe, 2)
    },
    f6VText() {
      const text = ['严重危险级', '中危险级', '轻危险级']
      return text[this.f6.v - 1]
    },
  },
  created() {
    this.index = Number(this.$route.params.id)
    this.title = this.type[this.index - 1]
    this.image = this.formula[this.index - 1]
    if (this.index === 2) {
      this.fireType = [
        { value: 2.25, text: '纸和纤维材料' },
        { value: 2, text: '棉花和塑料颗粒' },
        { value: 1.5, text: '电缆沟、电子计算机房' },
        { value: 2, text: '油变压器、油开关、柴油发电机' },
        { value: 1.34, text: '乙醇' },
        { value: 1, text: '汽油、丙酮、煤油、甲烷、柴油、润滑油' },
      ]
    }
    if (this.index === 3) {
      this.fireType = [
        { value: 7.54, text: '固体表面火灾' },
        { value: 10, text: '图书、档案、文物库火灾' },
        { value: 9, text: '油变压器、油开关、柴油发电机火灾' },
        { value: 8, text: '通讯电子机房火灾' },
      ]
    }
    if (this.index === 4) {
      this.fireType = [
        { value: 36.53, text: '固体表面火灾' },
        { value: 48.44, text: '图书、档案、文物库火灾' },
        { value: 43.2, text: '油变压器、油开关、柴油发电机火灾' },
        { value: 37.5, text: '通讯电子机房火灾' },
      ]
    }
    if (this.index === 5) {
      this.fireType = [
        { value: 0.13, text: '固体表面火灾' },
        { value: 0.14, text: '电缆隧道、发电机房火灾' },
        { value: 0.13, text: '通讯电子机房火灾' },
      ]
    }
    if (this.index === 6) {
      this.fireType = [
        { value: 1, text: '无室内消火栓系统和灭火系统' },
        { value: 0.9, text: '仅有室内消火栓系统' },
        { value: 0.7, text: '仅有灭火系统' },
        { value: 0.5, text: '有室内消火栓系统和灭火系统' },
        { value: 0.3, text: '易燃易爆罐区或堆场' },
      ]
    }
  },
  methods: {
    onFireTypeChoose(val) {
      if (this.index === 2) {
        this.f2.kb = val.value
        this.f2.kbText = val.text
      }
      if (this.index === 3) {
        this.f3.c1 = val.value
        this.f3.c1Text = val.text
      }
      if (this.index === 4) {
        this.f4.c1 = val.value
        this.f4.c1Text = val.text
      }
      if (this.index === 5) {
        this.f5.c2 = val.value
        this.f5.c2Text = val.text
      }
      if (this.index === 6) {
        this.f6.k = val.value
        this.f6.kText = val.text
      }
    },
    onBuildTypeChoose(val) {
      if (this.index === 2) {
        this.f2.p = val.value
        this.f2.pText = val.text
      }
      if (this.index === 3) {
        this.f3.p = val.value
        this.f3.pText = val.text
      }
      if (this.index === 4) {
        this.f4.p = val.value
        this.f4.pText = val.text
      }
    },
    onBuildUseChoose(val) {
      this.f6.type = val.value
      this.f6.typeText = val.text
      if (val.value === 1) {
        this.places = [
          {
            value: 1,
            text: '严重危险级',
            brief: `1. 县级及以上的文物保护单位、档案馆、博物馆的库房、展览室、阅览室<br/>
2. 设备贵重或可燃物多的实验室<br/>
3. 广播电台、电视台的演播室、道具间和发射塔楼<br/>
4. 专用电子计算机房<br/>
5. 城镇及以上的邮政信函和包裹分检房、邮袋库、通信枢纽及其电信机房<br/>
6. 客房数在50间以上的旅馆、饭店的公共活动用房、多功能厅、厨房<br/>
7. 体育场（馆）、电影院、剧院、会堂、礼堂的舞台及后台部位<br/>
8. 住院床位在50张及以上的医院的手术室、理疗室、透视室、心电图室、药房、住院部、门诊部、病历室<br/>
9. 建筑面积在2000㎡及以上的图书馆、展览馆的珍藏室、阅览室、书库、展览厅<br/>
10. 民用机场的候机厅、安检厅及空管中心、雷达机房<br/>
11. 超高层建筑和一类高层建筑的写字楼、公寓楼<br/>
12. 电影、电视摄影棚<br/>
13. 建筑面积在1000㎡及以上的经营易燃易爆化学物品的商场、商店的库房及铺面<br/>
14. 建筑面积在200㎡及以上的公共娱乐场所<br/>
15. 老人住宿床位在50张及以上的养老院<br/>
16. 幼儿住宿床位在50张及以上的托儿所、幼儿园<br/>
17. 学生住宿床位在100张及以上的学校集体宿舍<br/>
18. 县级及以上的党政机关办公大楼的会议室<br/>
19. 建筑面积在500㎡及以上的车站和码头的候车（船）室、行李房<br/>
20. 城市地下铁道、地下观光隧道<br/>
21. 汽车加油站、加气站<br/>
22. 机动车交易市场（包括旧机动车交易市场）及其展销厅<br/>
23. 民用液化气、天然气灌装站、换瓶站、调压站<br/>
`,
          },
          {
            value: 2,
            text: '中危险级',
            brief: `1. 县级以下的文物保护单位、档案馆、博物馆的库房、展览室、阅览室<br/>
2. 一般的实验室<br/>
3. 广播电台电视台的会议室、资料室<br/>
4. 设有集中空调、电子计算机、复印机等设备的办公室<br/>
5. 城镇以下的邮政信函和包裹分检房、邮袋库、通信枢纽及其电信机房<br/>
6. 客房数在50间以下的旅馆、饭店的公共活动用房、多功能厅和厨房<br/>
7. 体育场（馆）、电影院、剧院、会堂、礼堂的观众厅<br/>
8. 住院床位在50张以下的医院的手术室、理疗室、透视室、心电图室、药房、住院部、门诊部、病历室<br/>
9. 建筑面积在2000㎡以下的图书馆、展览馆的珍藏室、阅览室、书库、展览厅<br/>
10. 民用机场的检票厅、行李厅<br/>
11. 二类高层建筑的写字楼、公寓楼<br/>
12. 高级住宅、别墅<br/>
13. 建筑面积在1000㎡以下的经营易燃易爆化学物品的商场、商店的库房及铺面<br/>
14. 建筑面积在200㎡ 以下的公共娱乐场所<br/>
15. 老人住宿床位在50张以下的养老院<br/>
16. 幼儿住宿床位在50张以下的托儿所、幼儿园<br/>
17. 学生住宿床位在100张以下的学校集体宿舍<br/>
18. 县级以下的党政机关办公大楼的会议室<br/>
19. 学校教室、教研室<br/>
20. 建筑面积在500㎡ 以下的车站和码头的候车（船）室、行李房<br/>
21. 百货楼、超市、综合商场的库房、铺面<br/>
22. 民用燃油、燃气锅炉房<br/>
23. 民用的油浸变压器室和高、低压配电室<br/>
`,
          },
          {
            value: 3,
            text: '轻危险级',
            brief: `1. 日常用品小卖店及经营难燃烧或非燃烧的建筑装饰材料商店<br/>
2. 未设集中空调、电子计算机、复印机等设备的普通办公室<br/>
3. 旅馆、饭店的客房<br/>
4. 普通住宅<br/>
5. 各类建筑物中以难燃烧或非燃烧的建筑构件分隔的并主要存贮难燃烧或非燃烧材料的辅助房间<br/>
`,
          },
        ]
      } else {
        this.places = [
          {
            value: 1,
            text: '严重危险级',
            brief: `<div class="color-blue3">厂房：<br/>
1. 闪点＜60℃的油品和有机溶剂的提炼、回收、洗涤部位及其泵房、灌桶间<br/>
2. 橡胶制品的涂胶和胶浆部位<br/>
3. 二硫化碳的粗馏、精馏工段及其应用部位<br/>
4. 甲醇、乙醇、丙酮、丁酮、异丙醇、醋酸乙酯、苯等的合成、精制厂房<br/>
5. 植物油加工厂的浸出厂房<br/>
6. 洗涤剂厂房石蜡裂解部位、冰醋酸裂解厂房<br/>
7. 环氧氢丙烷、苯乙烯厂房或装置区<br/>
8. 液化石油气灌瓶间<br/>
9. 天然气、石油伴生气、水煤气或焦炉煤气的净化（如脱硫）厂房压缩机室及鼓风机室<br/>
10. 乙炔站、氢气站、煤气站、氧气站<br/>
11. 硝化棉、赛璐珞厂房及其应用部位<br/>
12. 黄磷、赤磷制备厂房及其应用部位<br/>
13. 樟脑或松香提炼厂房，焦化厂精萘厂房<br/>
14. 煤粉厂房和面粉厂房的碾磨部位<br/>
15. 谷物筒仓工作塔、亚麻厂的除尘器和过滤器室<br/>
16. 氯酸钾厂房及其应用部位<br/>
17. 发烟硫酸或发烟硝酸浓缩部位<br/>
18. 高锰酸钾、重铬酸钠厂房<br/>
19. 过氧化钠、过氧化钾、次氯酸钙厂房<br/>
20. 各工厂的总控制室、分控制室<br/>
21. 国家和省级重点工程的施工现场<br/>
22. 发电厂（站）和电网经营企业的控制室、设备间<br/>
</div>
<div class="color-geekblue3 margin-top">
库房：<br/>
1. 化学危险物品库房<br/>
2. 装卸原油或化学危险物品的车站、码头<br/>
3. 甲、乙类液体储罐区、桶装库房、堆场<br/>
4. 液化石油气储罐区、桶装库房、堆场<br/>
5. 棉花库房及散装堆场<br/>
6. 稻草、芦苇、麦秸等堆场<br/>
7. 赛璐珞及其制品、漆布、油布、油纸及其制品，油绸及其制品库房<br/>
8. 酒精度为60度以上的白酒库房<br/>
</div>
`,
          },
          {
            value: 2,
            text: '中危险级',
            brief: `<div class="color-blue3">厂房：<br/>
1. 闪点≥60℃的油品和有机溶剂的提炼、回收工段及其抽送泵房<br/>
2. 柴油、机器油或变压器油灌桶间<br/>
3. 润滑油再生部位或沥青加工厂房<br/>
4. 植物油加工精炼部位<br/>
5. 油浸变压器室和高、低压配电室<br/>
6. 工业用燃油、燃气锅炉房<br/>
7. 各种电缆廊道<br/>
8. 油淬火处理车间<br/>
9. 橡胶制品压延、成型和硫化厂房<br/>
10. 木工厂房和竹、藤加工厂房<br/>
11. 针织品厂房和纺织、印染、化纤生产的干燥部位<br/>
12. 服装加工厂房、印染厂成品厂房<br/>
13. 麻纺厂粗加工厂房、毛涤厂选毛厂房<br/>
14. 谷物加工厂房<br/>
15. 卷烟厂的切丝、卷制、包装厂房<br/>
16. 印刷厂的印刷厂房<br/>
17. 电视机、收录机装配厂房<br/>
18. 显像管厂装配工段烧枪间<br/>
19. 磁带装配厂房<br/>
20. 泡沫塑料厂的发泡、成型、印片、压花部位<br/>
21. 饲料加工厂房<br/>
22. 地市级及以下的重点工程的施工现场<br/>
</div>
<div class="color-geekblue3 margin-top">库房：<br/>
1. 丙类液体储罐区、桶装库房、堆场<br/>
2. 化学、人造纤维及其织物和棉、毛、丝、麻及其织物的库房、堆场<br/>
3. 纸、竹、木及其制品的库房、堆场<br/>
4. 火柴、香烟、糖、茶叶库房<br/>
5. 中药材库房<br/>
6. 橡胶、塑料及其制品的库房<br/>
7. 粮食、食品库房、堆场<br/>
8. 电脑、电视机、收录机等电子产品及家用电器库房<br/>
9. 汽车、大型拖拉机停车库<br/>
10. 酒精度小于60度的白酒库房<br/>
11. 低温冷库<br/>
</div>
`,
          },
          {
            value: 3,
            text: '轻危险级',
            brief: `<div class="color-blue3">厂房：<br/>
1. 金属冶炼、铸造、铆焊、热轧、锻造、热处理厂房<br/>
2. 玻璃原料熔化厂房<br/>
3. 陶瓷制品的烘干、烧成厂房<br/>
4. 酚醛泡沫塑料的加工厂房<br/>
5. 印染厂的漂炼部位<br/>
6. 化纤厂后加工润湿部位<br/>
7. 造纸厂或化纤厂的浆粕蒸煮工段<br/>
8. 仪表、器械或车辆装配车间<br/>
9. 不燃液体的泵房和阀门室<br/>
10. 金属（镁合金除外）冷加工车间<br/>
11. 氟里昂厂房<br/>
</div>
<div class="color-geekblue3 margin-top">
库房：<br/>
1. 钢材库房、堆场<br/>
2. 水泥库房、堆场<br/>
3. 搪瓷、陶瓷制品库房、堆场<br/>
4. 难燃烧或非燃烧的建筑装饰材料库房、堆场<br/>
5. 原木库房、堆场<br/>
6. 丁、戊类液体储罐区、桶装库房、堆场<br/>
</div>
`,
          },
        ]
      }
    },
  },
  watch: {
    f3V(newVal) {
      if (newVal > 3600) {
        this.$toast.failed('一个防护区容积不宜大于3600m³')
      }
    },
    f4V(newVal) {
      if (newVal > 3600) {
        this.$toast.failed('一个防护区容积不宜大于3600m³')
      }
    },
  },
}
</script>
<style lang="stylus" scoped>
.custom-brief
  color #858b9c
  font-size 24px
  line-height 1.4
  margin-top 8px
</style>
