<template>
  <div class="container">
    <nav-bar :title="$route.params.parentName"></nav-bar>
    <md-field>
      <div v-if="menus.length > 0">
        <md-cell-item v-for="item in menus" :key="item.id" :title="item.name" arrow @click="handleClick(item)" :disabled="item.disabled" />
      </div>
      <md-result-page v-if="showResult && menus.length === 0"></md-result-page>
    </md-field>
  </div>
</template>

<script>
import * as menuApi from '@/api/menu'

export default {
  data() {
    return {
      menus: [],
      menuIds: [],
      showResult: false,
      isPro: this.$store.getters['user/isPro'],
      isSuperPro: this.$store.getters['user/isSuperPro'],
      isTest: this.$store.getters['user/isTest'],
    }
  },
  created() {
    if (this.isPro) {
      this.menuIds = this.$store.getters['user/info'].invite_code.client.menu_ids
    } else {
      this.menuIds = [26, 36, 43, 80, 85, 89, 45, 77, 74, 70]
    }
    this._getData(this.$route.params.parentId)
  },
  methods: {
    handleClick(val) {
      if (val.has_children) {
        this.$router.push({ name: 'sub-menu', params: { parentId: val.id, parentName: val.name } })
      } else {
        this.$router.push({ name: 'question', params: { menuId: val.id, menuName: val.name } })
      }
    },
    _getData(parentId) {
      menuApi.list({ parent_id: parentId }).then((res) => {
        this.menus = this._initMenu(res.data)
        if (this.menus.length > 0) {
          this.showResult = false
        } else {
          this.showResult = true
        }
      })
    },
    _initMenu(data) {
      return data
      // if (this.isPro) {
      //   return data.filter(item => {
      //     if (!_.includes(this.menuIds, item.id)) {
      //       return false
      //     }
      //     return true
      //   })
      // } else if (this.isTest) {
      //   return data
      // } else {
      //   return data.map(item => {
      //     if (!_.includes(this.menuIds, item.id)) {
      //       item.disabled = true
      //       item.name += '（企业版专属）'
      //     }
      //     return item
      //   })
      // }
    },
  },
  beforeRouteUpdate(to, from, next) {
    this._getData(to.params.parentId)
    next()
  },
}
</script>
<style lang="stylus" scoped></style>
