<template>
  <div class="container">
    <div class="row search-bar">
      <search v-model="searchVal" @onSearch="handleSearch"></search>
    </div>
    <md-field>
      <md-cell-item v-for="item in menus" :key="item.id" :title="item.name" arrow @click="handleClick(item)" />
    </md-field>
  </div>
</template>

<script>
import * as normMenuApi from '@/api/normMenu.js'

export default {
  data() {
    return {
      nativeMenus: [],
      menus: [],
      searchVal: '',
    }
  },
  created() {
    this._getData()
  },
  methods: {
    handleClick(val) {
      this.$router.push({ name: 'norm-title', params: { normMenuId: val.id, normMenuName: val.name } })
    },
    handleSearch() {
      this.menus = this.menus.filter(item => item.name.indexOf(this.searchVal) > -1)
    },
    _getData() {
      normMenuApi.list().then(res => {
        this.menus = res.data
        this.nativeMenus = res.data
      })
    },
  },
  watch: {
    searchVal(newVal) {
      this.menus = this.nativeMenus.filter(item => item.name.indexOf(newVal) > -1)
    },
  },
}
</script>
<style lang="stylus" scoped></style>
