<template>
  <div class="container">
    <nav-bar :title="normMenuName"></nav-bar>
    <div class="row search-bar">
      <search v-model="searchVal" @onSearch="handleSearch"></search>
    </div>
    <md-field>
      <md-cell-item v-for="item in titles" :key="item.id" :title="item.name" arrow @click="handleClick(item)">
        <p v-if="showContent" slot="children" style="font-size:0.24rem;color:#858B9C;" v-html="getSimpleText(item.body, keyword)"></p>
      </md-cell-item>
    </md-field>
  </div>
</template>

<script>
import * as normApi from '@/api/norm.js'
import { getSimpleText } from '@/utils/utils.js'

export default {
  data() {
    return {
      titles: [],
      searchVal: '',
      normMenuId: '',
      normMenuName: '',
      keyword: '',
      showContent: false,
    }
  },
  created() {
    this._getData()
  },
  methods: {
    getSimpleText,
    handleClick(val) {
      const params = { id: val.id }
      if (this.keyword) {
        params.keyword = this.keyword
      }
      this.$router.push({ name: 'norm', params })
    },
    handleSearch(val) {
      this._getData(val)
    },
    _getData(keyword) {
      this.normMenuId = this.$route.params.normMenuId
      this.normMenuName = this.$route.params.normMenuName
      if (keyword) {
        this.keyword = keyword
        normApi.list({ keyword, norm_menu_id: this.normMenuId }).then(res => {
          this.titles = res.data
          this.showContent = true
        })
      } else {
        this.keyword = ''
        this.showContent = false
        normApi.list({ norm_menu_id: this.normMenuId }).then(res => {
          this.titles = res.data
        })
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from.name === 'norm-menu') {
        vm.searchVal = ''
        vm.titles = []
        vm._getData()
      }
    })
  },
}
</script>
<style lang="stylus" scoped></style>
